import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
// import developers1 from '../img/layout.svg';
// import developers from '../img/icon-dev.png';
import iconDevelop1 from '../img/checklist.png'
import iconDevelop2 from '../img/html.png'
import iconDevelop3 from '../img/coding.png'
// import undraw_JavaScript_Frameworks_x21l from '../img/undraw_JavaScript_Frameworks_x21l.svg';
// import undraw_version_control_9bpv from '../img/undraw_version_control_9bpv.svg'
import undraw_version_control_9bpv from '../img/aaa.svg'
// import VidDevelopers from '../components/VidDevelopers';
// import VidDevelopers from '../img/video/developers.mp4'
const Developers = () => ( <Layout>
  <div className="skipScroll">
  <Helmet
  title={'Crypto Developer Documentation | Temporal Blockchain API'}
  meta={[
      {
        name: 'description',
        content: 'Our developer documentation provides access to the temtum API with use guides on how best to implement TEM into your platforms.'
      }
    ]}
    />
    <div className="hd-space"/>
    <div className="page skipScroll">
      <section className="nopadX skipScroll  bg-blue-3 text-white">
        <video id="video-background" loop="loop" autoPlay="autoPlay" playsInline="playsinline" muted="muted">
          <source src={require('../img/video/developers.mp4')} type="video/mp4"/>
        </video>
        <div className="darkleft blueblue video-overlay"/>
        <div className="wow3 video-overlay"/>
        <div className="py-5X">
          <div className="container">
            <div className="row text-centerX">
              <div className="col-12 col-md-10 col-lg-7">
                <h6 className="line red mb-4" data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
                  temtum for <span className="font-weight-bold">Developers</span>
                </h6>
                <h2 className="display-1 pb-4" data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">
                  Build a decentralized future where people hold the power.
                </h2>
                <p className="lead pb-4 text-white" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600">
                  Develop your own custom payment systems on the Temporal Blockchain to provide solutions to commercial problems.
                </p>
                <a href="/developers/docs/" className="btn btn-secondary mr-3" data-aos="fade-up" data-aos-duration="500" data-aos-delay="800">
                  View Documentation
                </a>
                <a href="https://explorer.temtum.com/" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light" data-aos="fade-up" data-aos-duration="500" data-aos-delay="800">
                  Block Explorer
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section className="developers text-white skipScroll pt-5 pb-5  bg-blue-3">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h2 className="display-4  pb-2 text-red" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
                Develop and scale payment solutions with <strong>Temporal</strong>
              </h2>
              <p className="leadX text-mutedX text-white" data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
                The Temporal network is a world first and offers the most secure solution of any existing currency.
                <Link to="/platform" className="text-white underlineX ml-2">
                  Learn more &rArr;
                </Link>
              </p>
            </div>
            <div className="col-md-5 text-right">
              <a href="/developers/docs/" className="btn btn-outline-light mt-4">
                View the API
              </a>
            </div>
          </div>
        </div>
      </section>





      <section className="">

        <div className="container">
          <div className="row align-items-center mb-5 border-bottom pb-5">
            <div className="col-md-8">
              <h2 className="display-3 font-weight-bold mb-4">
                temtum Developer Documentation
              </h2>
              <p className="lead">
                Start your project with our development guides
              </p>
              <a href="/developers/docs/" className="btn btn-outline-dark">
                View Documentation
              </a>
            </div>
            <div className="col-md-4 ml-autoX pr-5">
              <img src={undraw_version_control_9bpv} className="" alt=""/>
            </div>
          </div>
          </div>



        <div className="container">
          <div className="row pb-5">
            <div className="col-md-12 mx-auto">

              <p className="font-weight-bold line red mb-5">
                How to get started
              </p>





              <div className="row">
                <div className="col-md-4">


                  <img className="img-fluid d-inline-block icon-32 mr-3" src={iconDevelop1} alt=""/>
                  <p className=" d-inline-block text-extra-small font-weight-bolder lead text-blue-3">
                    Learn
                  </p>
                  <div className="pt-3">
                    <p className="">Fully documented for developers</p>
                    <p className="small">
                    <a href="/developers/docs/" className="btn btn-outline-dark btn-sm">
                      Learn
                    </a>
                      </p>
                  </div>
              </div>


                <div className="col-md-4 offset-top-50 offset-md-top-0">
                  <img className="img-fluid d-inline-block icon-32 mr-3" src={iconDevelop2} alt=""/>
                  <p className=" d-inline-block text-extra-small font-weight-bolder lead text-blue-3">
                    Explore
                  </p>
                  <div className="pt-3">
                    <p className="">
                      Working with the Temporal Blockchain API
                    </p>
                    <p className="small">
                    <a href="/developers/docs/" className="btn btn-outline-dark btn-sm">
                      Explore
                    </a>
                      </p>
                  </div>
              </div>


                <div className="col-md-4 offset-top-50 offset-md-top-0">
                  <img className="img-fluid d-inline-block icon-32 mr-3" src={iconDevelop3} alt=""/>
                  <p className=" d-inline-block text-extra-small font-weight-bolder lead text-blue-3">
                    Build
                  </p>
                  <div className="pt-3">
                    <p className="">Build your TEM Powered application</p>
                    <p className="small">
                    <a href="/developers/docs/" className="btn btn-outline-dark btn-sm">
                      Build
                    </a>
                      </p>
                </div>
            </div>


            </div>


            </div>
              </div>
                </div>



      </section>

    </div>
  </div>
</Layout> )
export default Developers
